<template>
  <div class="layout-wrapper">
    <TheHeader :only-logo="isBrand(CUSTOM_APPEARANCE.MBH)" />

    <div
      v-if="!isBrand(CUSTOM_APPEARANCE.MBH)"
      class="is-flex is-justify-content-center is-hidden-touch mt-3"
    >
      <div class="usp-element">
        <b-icon
          icon-pack="fa"
          icon="check-double"
          type="is-white"
          class="mr-2"
        ></b-icon>

        <span class="has-text-white">{{ $t("af:header.title.usp_1") }}</span>
      </div>
      <div class="usp-element">
        <b-icon
          icon-pack="fa"
          icon="check-double"
          type="is-white"
          class="mr-2"
        ></b-icon>

        <span class="has-text-white">{{ $t("af:header.title.usp_2") }}</span>
      </div>
      <div class="usp-element">
        <b-icon
          icon-pack="fa"
          icon="check-double"
          type="is-white"
          class="mr-2"
        ></b-icon>

        <span class="has-text-white">{{ $t("af:header.title.usp_3") }}</span>
      </div>
    </div>

    <div
      class="layout-container is-flex is-justify-content-center is-flex-grow-1"
    >
      <div class="af-container" v-if="hasDefaultOffer && showSelectedOffer">
        <div class="offer-tile">
          <SelectedOffer />
        </div>
      </div>

      <div class="af-container">
        <slot />
      </div>
    </div>

    <TheFooter />
  </div>
</template>

<script>
import { CUSTOM_APPEARANCE } from "@/utils/const";
import { isBrand } from "@/utils/util";
import { validateLandingParams } from "../utils/landingParamUtil";

export default {
  name: "AppLayoutRegisterLogin",
  methods: { isBrand },
  components: {
    TheHeader: () => import("@/components/TheHeader"),
    TheFooter: () => import("@/components/TheFooter"),
    SelectedOffer: () => import("@/components/SelectedOffer"),
  },
  data() {
    return {
      showSelectedOffer: false,
    };
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    hasDefaultOffer() {
      return !!this.$route.query.offer;
    },
  },
  async created() {
    try {
      await this.$store.dispatch("offers/fetchOfferPreview", this.$route.query.offer);
      this.showSelectedOffer = validateLandingParams(this.$route.query);
    } catch {
      this.showSelectedOffer = false;
    }
  },
};
</script>

<style scoped lang="scss">
.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 0;
  flex-direction: column;

  .af-container {
    flex-grow: 1;
    max-width: 560px;
    width: 100%;

    .offer-tile {
      max-width: 400px;
      height: initial;
      padding: 2rem 0.5rem 1rem 0.5rem;
    }

    .offer-card {
      width: auto;
      height: auto;
    }

    .action-tile {
      max-width: 400px;
      margin-top: initial;
    }
  }
}

.usp-element {
  padding: 10px;
}

.layout-container {
  flex-direction: column;
}

.mbh {
  .offer-tile {
    background-image: url("../assets/images/mbh_mobile_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

@media only screen and (min-width: 769px) {
  .layout-wrapper {
    background-image: url("../assets/images/background-sport-car.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .layout-container {
    flex-direction: row;
  }

  .af-container {
    margin-top: 4rem !important;
  }

  .offer-tile {
    background-image: none;
    margin-right: 2rem;
    margin-top: 0;
  }

  .mbh {
    .layout-wrapper {
      background-image: url("../assets/images/mbh_desktop_bg.png");

      .af-container {
        max-width: 660px;
      }
    }
  }

  .mbh {
    .offer-tile {
      background: none;
    }
  }
}
</style>
